/**
 * These theme values will override the base theme.
 *
 * We're passing these values to Layout component;
 * The Layout component merges these values with the
 * base theme.
 *
 */

const teal = {
  100: '#CDFCDB',
  200: '#9CFAC1',
  300: '#69F0AB',
  400: '#43E2A1',
  500: '#0DD093',
  600: '#09B28E',
  700: '#069585',
  800: '#047876',
  900: '#025C63'
}

const purple = {
  100: '#20ade6',
  200: '#20ade6',
  300: '#1999d9',
  400: '#1999d9',
  500: '#286fb2',
  600: '#286fb2',
  700: '#286fb2',
  800: '#0d3c69',
  900: '#0d3c69'
}

const colors = {
  colors: {
    betaLighter: teal[100],
    betaLight: teal[300],
    beta: teal[500],
    betaDark: teal[600],
    betaDarker: teal[800],
    alphaLighter: purple[100],
    alphaLight: purple[300],
    alpha: purple[500],
    alphaDark: purple[600],
    alphaDarker: purple[800],
    background: `#FFF`,
    headerBg: `#F6F7FA`,
    footerBg: `#F6F7FA`
  }
}

export default colors;

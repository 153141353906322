import React  from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "theme-ui";
import Layout from "@solid-ui-layout/Layout";
import Seo from "@solid-ui-components/Seo";
import Divider from "@solid-ui-components/Divider";
import Hero from "@solid-ui-blocks/Hero/Block01";
import StackTitle from "@solid-ui-components/StackTitle";
import GetStarted from "@solid-ui-blocks/CallToAction/Block01";
import { normalizeBlockContentNodes } from "@blocks-helpers";
import AppWrapper from '@helpers/appContext';
import theme from "./_theme";
import { styles } from "./styles/_index.styles";
import { v4 } from 'uuid';

const Index = (props) => {
  const { selectedLanguage } = props;
  const { allBlockContent } = useStaticQuery(query);
  
  const content = normalizeBlockContentNodes(allBlockContent?.nodes),
    hero = content[`hero.${selectedLanguage}`],
    featuredProduct = content[`featured-product.${selectedLanguage}`];

  return (
    <Layout theme={theme} {...props}>
      <Seo title="Home" />

      <Divider space="4" />

      {/* Amera Hero */}
      <Container variant="full" sx={styles.heroContainer}>
        <Hero content={hero} reverse />
      </Container>
      <Divider space="4" />

      {/* Amera Featured Product: AmeraKey */}
      <Container key={v4()}>
        <StackTitle
          kicker={featuredProduct.kicker}
          title={featuredProduct.title}
          lines={[featuredProduct.text_1, featuredProduct.text_2]}
          align={["center", "left"]}
          space={3}
        />
      </Container>
      <Divider space="5" />

      {/* Amera Early Access */}
      <Container sx={styles.getStartedContainer}>
        <GetStarted content={content[`book-demo.${selectedLanguage}`]} />
      </Container>
      <Divider space="5" />

    </Layout>
  );
};

const query = graphql`
  query siteIndexBlockContent {
    allBlockContent(
      filter: {
        page: {
          in: [
            "site/index/hero"
            "site/index/products"
            "site/index/why-choose-us"
            "site/index/featured-product"
            "site/index/stats"
            "site/shared/book-demo"
          ]
        }
      }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`;

const IndexPage = (props) => (
  <AppWrapper>
    <Index {...props}/>
  </AppWrapper>
);

export default IndexPage;

/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

export const styles = {
  heroContainer: {
    bg: `#F6F7FA`,
    pt: 5,
    pb: 6,
  },
  sectionTwoContainer: {

  },
  buildBrandContainer: {
    position: `relative`,
    "::before": {
      content: `" "`,
      size: `full`,
      position: `absolute`,
      right: 0,
      top: `13%`,
      zIndex: -1,
      opacity: 0.3,
      backgroundSize: `40%`,
    },
  },
  getStartedContainer: {
    position: `relative`,
    "::before": {
      content: `" "`,
      size: `full`,
      position: `absolute`,
      top: 0,
      right: 7,
      zIndex: -1,
      backgroundSize: `55%`,
    },
  },
};
